<template>
  <div>
    <DiagnosesFilter
      v-if="$can('filter', 'module_system_settings.settings.admin_diagnoses.diagnoses')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Diagnoses" iconTable="GitBranchIcon" />
      <div class="m-1">
        <DiagnosesEdit
          v-if="isEditDiagnosesSidebarActive"
          :is-edit-diagnoses-sidebar-active.sync="isEditDiagnosesSidebarActive"
          :itemEdit="itemEdit"
          @editDiagnosesSuccess="editDiagnosesSuccess"
        />
        <DiagnosesAdd
          :is-add-new-diagnoses-sidebar-active.sync="
            isAddNewDiagnosesSidebarActive
          "
          @createDiagnoses="createDiagnoses"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                @click="isAddNewDiagnosesSidebarActive = true"
                :disabled="loading"
                variant="primary"
                v-if="$can('store', 'module_system_settings.settings.admin_diagnoses.diagnoses')"
              >
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Diagnoses</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Update'"
            @click="editDiagnoses(data.item)"
            v-if="$can('update', 'module_system_settings.settings.admin_diagnoses.diagnoses')"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteDiagnoses(data.item)"
            v-if="$can('destroy', 'module_system_settings.settings.admin_diagnoses.diagnoses')"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import axiosD from "@/services/admin/consults/diagnoses";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import DiagnosesEdit from "./DiagnosesEdit.vue";
import DiagnosesAdd from "./DiagnosesAdd.vue";
import DiagnosesFilter from "./DiagnosesFilter.vue";

import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    DiagnosesEdit,
    DiagnosesAdd,
    DiagnosesFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        // A regular column
        // A regular column
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditDiagnosesSidebarActive: false,
      isAddNewDiagnosesSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      diagnosesFilter: null,
      filtro: false,
      stateFilter: null,
    };
  },
  mounted() {
    this.getDiagnoses();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getDiagnoses(newVal);
      } else {
        this.searchFilter(newVal, this.stateFilter);
      }
    },
  },
  methods: {
    getDiagnoses() {
      axiosD
        .diagnosesList(this.perPage)
        .then(({ data, total, current_page, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.perPage = per_page;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosD
          .diagnosesPagination(this.perPage, page)
          .then(({ data, total, current_page, per_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.perPage = per_page;
            this.currentPage.page = current_page;
          });
      } else {
        axiosD
          .diagnosesFilterPagination(
            this.diagnosesFilter,
            page /*this.perPage */
          )
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    createDiagnoses(value) {
      if (value) {
        this.$refs.toast.success("Diagnoses added successfully");
      } else {
        this.$refs.toast.danger("Diagnoses not added");
      }
      this.isAddNewDiagnosesSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    editDiagnoses(item) {
      this.isEditDiagnosesSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editDiagnosesSuccess(value) {
      if (value) {
        this.$refs.toast.success("Diagnoses updated successfully");
      } else {
        this.$refs.toast.error("Error updating Diagnoses");
      }
      this.isEditDiagnosesSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    searchFilter(perPage = 10, value) {
      if (this.filtro) {
        this.perPage = perPage;
        this.loading = true;
        this.diagnosesFilter = value;
        axiosD
          .diagnosesFilter(value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getDiagnoses();
      }
    },
    deleteDiagnoses(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosD.diagnosesDelete(item.id).then((response) => {
            this.$refs.toast.success("Diagnoses has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style>
</style>